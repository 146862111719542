import React from "react";
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
export default function Play(props) {
  const { handleClick } = props;
  
  return (
    <button className="player__button" onClick={() => handleClick()}>
      <PauseRoundedIcon />
    </button>
  );
}
