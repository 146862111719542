import React from "react";

import Play from "./Play";
import Pause from "./Pause";
import Replay from "./Replay";
import './styles.css';
import useAudioPlayer from './useAudioPlayer';

function Audio() {
  const { curTime, duration, playing, setPlaying, setClickedTime } = useAudioPlayer();

  return (
    <div className="player" >
      <audio id="audio">
        <source src="audio/demo.mp3" />
        Your browser does not support the <code>audio</code> element.
      </audio>
      <div className="controls">
        {playing ? 
          <Pause handleClick={() => setPlaying(false)} /> :
          <Play handleClick={() => setPlaying(true)} />
        }
        
      </div>
       <div className="label">Listen to a demo</div>
    </div>
  );
}

export default Audio;
