import React from "react";

import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
export default function Play(props) {
  const { handleClick } = props;

  return (
    <button className="player__button" onClick={() => handleClick()}>
      <PlayArrowRoundedIcon />
    </button>
  );
}
