import 'react-h5-audio-player/lib/styles.css';
import './PodleAudioPlayer.css';
import AudioPlayer from 'react-h5-audio-player';

function PodleAudioPlayer() {
    return (
        
       

        <div className="row audio-player">
                       
                
            <div className="col-3 audio-player-btn">
                <audio
        controls
        src="audio/demo.mp3">
            Your browser does not support the
            <code>audio</code> element.
    </audio>

            </div>
            <div className="col-9 audio-player-title">m'écouter</div>
                
        </div>
    );
}
export default PodleAudioPlayer;
