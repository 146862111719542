import logo from './logo.svg';
import './App.css';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import PodleAudioPlayer from './PodleAudioPlayer';
import {Helmet} from "react-helmet";
import Audio from './audio-player/Audio';
import axios from 'axios';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { InlineWidget } from "react-calendly";



function App() {

    const [name, setName ] = useState('');
    const [email, setEmail ] = useState('');
    const [message, setMessage ] = useState('');
    const [emailSubscribe, setEmailSubscribe ] = useState('');


    const handleNameChange = (e) => setName(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handleMessageChange = (e) => setMessage(e.target.value);
    const handleEmailSubscribe= (e) => setEmailSubscribe(e.target.value);

    const subscribe = (event)=>{
        event.preventDefault();
        axios.post(`https://api.podle.io/contact`, { name : "Subscriber", email: emailSubscribe, message: "Subscribed to newsletter" })
        .then(res => {
            setEmailSubscribe('');
        });
        toast.success('✉️  Thank you for subscribing to our newsletter', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
        });
    }

    const sendMessage = (event)=>{
        event.preventDefault();
        axios.post(`https://api.podle.io/contact`, { name : name, email: email, message: message })
        .then(res => {
            setName('');
            setEmail('');
            setMessage('');
            
        });
        toast.success('✉️  Thank you for your message, we will contact you as soon as possible.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
        });
    }

    return (

    <div>
        <header className="header-area">            
            <div id="header-sticky" className="menu-area">
                <div className="container">
                    <div className="second-menu">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-lg-3">
                                <div className="logo">
                                    <a href="#"><img src="img/logo-podle.svg" alt="logo" width="37px"/></a>
                                    <span className="logo-header">Podle</span>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-8">
                                <div className="responsive"><i className="icon dripicons-align-right"></i></div>
                                <div className="main-menu text-right text-xl-right">
                                    <nav id="mobile-menu">
                                        <ul>
                                            <li className="has-sub">
												<a href="#parallax">Home</a>
											</li>
                                            <li><a href="#about">How does it work ?</a></li>
                                            <li><a href="#features">Features</a></li> 
                                            <li><a href="#pricing">Our plans</a></li>     
                                            <li><a href="/french">Français</a></li>     
                                                
											  {/* <li><a href="#blog">Blog</a></li> */}
                                            {/* <li><a href="#contact">Contact</a></li>                                             */}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-xl-2 text-right d-none d-xl-block">
                                <div className="header-btn second-header-btn">
                                    <a href="#contact" className="btn">Request your demo</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <main>
            <section id="parallax" className="slider-area slider-bg2 second-slider-bg d-flex fix first-section" >
                
                <div className="slider-shape ss-one layer" data-depth="0.10"><img src="img/shape/header-sape.png" alt="shape"/></div>
                
     		        <div className="slider-shape ss-eight layer" data-depth="0.50"></div>
                
                
                
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="slider-content second-slider-content left-center">   
                                            <div>
                                                <Audio/>    
                                            </div> 
                                        
                                        <h2 data-animation="fadeInUp" data-delay=".4s">Give your content <span>superpowers</span> 💪</h2>
                                        <p data-animation="fadeInUp" data-delay=".6s">Expand your audience by diversifying the format of your content, generate audios and videos from your articles, your newsletters and even your cooking recipes in just a few clicks.</p>
                                        <div className="slider-btn mt-30 mb-30">
                                            <a href="#contact" className="btn ss-btn" data-animation="fadeInUp" data-delay=".8s">Request your demo</a>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <img src="img/ui-design.png" alt="shape" className="s-img"/>
                                </div>
                            </div>
                    
                </div>
            </section>
            <section id="about" className="services-area services-bg pt-10 pb-20" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-10">
                            <div className="section-title text-center pl-40 pr-40 mb-45">
                                    <h2> How does it work ?</h2>
                                    
                                <p className="how-works-p">      
                                Podle integrates natively into your website, blog or newsletter to showcase your content by giving your visitors the choice of how they want to consume it: read, listen or watch
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-12 mb-30">
                            <div className="s-single-services active text-center">
                                <div className="services-icon">
                                  <img src="img/icon/f-icon1.png"/>
                                </div>
                                <div className="second-services-content">
                                    <h5> Content creation</h5>
                                    <p>Focus on creating content for your visitors and customers.</p>
                                    <a><span>1</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mb-30">
                            <div className="s-single-services text-center">
                                <div className="services-icon">
                                  <img src="img/icon/f-icon3.png"/>
                                </div>
                                <div className="second-services-content">
                                    <h5>Podle integration</h5>
                                    <p>Add and configure the Podle plugin in minutes.</p>
                                    <a><span>2</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mb-30">
                          <div className="s-single-services text-center">
                                <div className="services-icon">
                                  <img src="img/icon/f-icon2.png"/>
                                </div>
                                <div className="second-services-content">
                                    <h5>Automatic generation</h5>
									<p>Podle takes care of generating and adding audios and videos to your pages.</p>
                                    <a><span>3</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
            </section>
            <section className="video-area pt-100 pb-100 p-relative" id="features">                
                <div className="video-img2">
                    
                    <img src="img/bg/video-img.png" alt="mobile"/>
                
                    <a href="https://www.youtube.com/watch?v=kWgtFjMR2cE" className="popup-video"><img src="img/bg/play-btn.png" alt="play-btn.png"/></a>
                </div>
                <div className="video-img3">
                    <img src="img/shape/header-sape5.png" alt="header-sape4"/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                        </div>
                        <div className="col-xl-6">
                            <div className="video-wrap">
                                <div className="section-title w-title left-align mb-25">
                                    <h2>Features</h2>
                                </div>
                                <div className="video-content">
                                    <p>Automate and simplify your audio publishing workflow </p>
                                    <ul>
                                        <li>
                                            <div className="icon"><img src="img/icon/vs-icon.png"/></div> 
                                            <div className="text">Transform articles/newsletters with high quality voices.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon"><img src="img/icon/vs-icon.png"/></div> 
                                            <div className="text">Mixing with jingles, backgrounds, intro/outro...
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon"><img src="img/icon/vs-icon.png"/></div> 
                                            <div className="text">Storage of mp3 files, streaming, embedded player.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon"><img src="img/icon/vs-icon.png"/></div> 
                                            <div className="text">Creation of public and private podcasts (paywall).
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon"><img src="img/icon/vs-icon.png"/></div> 
                                            <div className="text">Automation allows fast execution (1600 words in 2 minutes).
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="store" className="choose-area pt-100 pb-60 p-relative" >
                <div className="chosse-img"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="choose-wrap">
                                <div className="section-title w-title left-align mb-25">
                                    <h2>Why ?</h2>
                                </div>
                                <div className="choose-content">
                                    <p className="why-p">International newspapers share their feedback on audio</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="single-testimonial">
                                    <div className="row">
                                        <div className='col-2'>
                                            <img src="img/the-washinton.png" alt="img" width="100%"/>
                                        </div>
                                        <div className='col-10'>
                                            <h6 className='testimonial-title'>Kat Downs Mulder</h6>
                                            <p className='testimonial-subtitle'>Editor-in-chief of Washington Post</p>
                                            <div className="qutation"><img src="img/bg/qutation.png" alt="qutation.png"/></div>
                                            <p>
                                            "Whether you're listening to a story while multitasking or absorbing a compelling narrative while on a walk, audio unlocks new opportunities to engage with our journalism in more convenient ways [...] Readers who listened to audio articles on our apps engaged more than three times longer with our content."
                                            </p>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="single-testimonial">
                                    <div className="row">
                                        <div className='col-2'>
                                            <img src="img/wsj.png" alt="img" width="100%"/>
                                        </div>
                                        <div className='col-10'>
                                            <h6 className='testimonial-title'>Jason Jedlinski</h6>
                                            <p className='testimonial-subtitle'>Head of Audience Touchpoints at The Wall Street Journal.</p>
                                            <div className="qutation"><img src="img/bg/qutation.png" alt="qutation.png"/></div>
                                            <p>
                                                "Our big success was ‘Listen to this article,’ an automatically generated audio version of every story on WSJ.com. It’s proven more habit-forming than our popular crossword puzzle — earning praise from students and seniors alike."
                                            </p>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="single-testimonial">
                                    <div className="row">
                                        <div className='col-2'>
                                            <img src="img/ringier.jpeg" alt="img" width="100%"/>
                                        </div>
                                        <div className='col-10'>
                                            <h6 className='testimonial-title'>Jacqueline Schraner</h6>
                                            <p className='testimonial-subtitle'>Subscription and Online Marketing Project Manager at Ringier Axel Springer.</p>
                                            <div className="qutation"><img src="img/bg/qutation.png" alt="qutation.png"/></div>
                                            <p>
                                                "In our e-paper, our customers can now have all articles read out to them by Hans and Marlene. That’s really great. We have received a lot of positive feedback on these two voices."
                                            </p>
                                        </div>
                                    </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* <section id="features" className="app-work pt-70 pb-100 p-relative" >            
                <div className="container">
                    <div className="row align-items-center ">
                        <div className="col-xl-6">
                            <div className="choose-wrap">
                                <div className="section-title w-title left-align mb-15">
                                    <h2>How does This App Work?</h2>
                                </div>
                                <div className="app-work-content mt-20">
                                    <ul>
                                        <li>
                                            <div className="icon"><img src="img/icon/apw-Icon1.png"/></div> 
                                            <div className="text">
                                                <h4>Make A Profile</h4>
                                                <p>Aliquam varius ligula nec leo tempus porta. Vestibulum suscipit leo at nunc imperdiet, quis lacinia nisi euismod.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon"><img src="img/icon/apw-Icon2.png"/></div> 
                                            <div className="text">
                                                <h4>Download It For Free</h4>
                                                <p>Aliquam varius ligula nec leo tempus porta. Vestibulum suscipit leo at nunc imperdiet, quis lacinia nisi euismod.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon"><img src="img/icon/apw-Icon3.png"/></div> 
                                            <div className="text">
                                                <h4>Enjoy This App</h4>
                                                <p>Aliquam varius ligula nec leo tempus porta. Vestibulum suscipit leo at nunc imperdiet, quis lacinia nisi euismod.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <img src="img/bg/app-work-img.png" alt="app-work-img" className="img"/>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section id="screen" className="screen-area services-bg services-two pt-100 pb-70"  >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                            <div className="section-title text-center pl-40 pr-40 mb-50">                               
                                <h2>Our App ScreenShots</h2>
                                <p>Quisque posuere mollis ipsum et molestie. Fusce cursus, risus vel scelerisque porttitor, leo quam vulputate nibh, sit amet blandit erat magna.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        
                          <div className="swiper-container">
                            <div className="swiper-wrapper">
                              <div className="swiper-slide"><img src="img/gallery/screen-img01.png" alt="slide 1"/></div>
                              <div className="swiper-slide"><img src="img/gallery/screen-img02.png" alt="slide 1"/></div>
                              <div className="swiper-slide"><img src="img/gallery/screen-img03.png" alt="slide 1"/></div>
                              <div className="swiper-slide"><img src="img/gallery/screen-img04.png" alt="slide 1"/></div>
                              <div className="swiper-slide"><img src="img/gallery/screen-img05.png" alt="slide 1"/></div>

                            </div>
                            <div className="swiper-pagination"></div>
                          </div>

          </div>
                    </div>
                    

            </section> */}

            <section id="integration" className="faq-area pb-100">
                <div className="container">
                    <div className="row align-items-end">                        
                        <div className="col-lg-6">
                            <div className="faq-img text-right">
                                <img src="img/icon/logos-icons-2.png" alt="img" className="img"/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="section-title left-align mb-50">                               
                                <h2>Native integration with your streaming apps </h2>
                                <p></p>
                            </div>
                            <div className="faq-wrap">
                                <div className="accordion" id="accordionExample">
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className="faq-btn" type="button" data-toggle="collapse"
                                                    data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                                    Automatic publishing to streaming apps
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseThree" className="collapse show" aria-labelledby="headingThree"
                                            data-parent="#accordionExample">
                                            <div className="card-body">
                                            Once your productions have been generated, Podle takes care of automatically publishing them to your favorite streaming applications (spotify, itunes, google podcasts, etc.).
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-toggle="collapse"
                                                    data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Publish to your domain
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                            <div className="card-body">
                                            Use our APIs to integrate our functionalities into your application: automatic sending of productions to your systems
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    RSS feed management
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div className="card-body">
                                            Create and manage RSS feeds for your subscribers and automate the addition of new productions.
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section id="subscribe" className="newslater-area pt-90 pb-100" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                            <div className="section-title text-center pl-20 pr-20 mb-30">
                                <h2>Subscribe to our newsletter</h2>
                                {/* <p>Soyez les premiers à être notifier .</p> */}
                            </div>
                        </div>
                    </div>
                  <div className="row justify-content-center">          
                      <div className="col-xl-6 col-lg-10">
                            <form onSubmit={subscribe} className="contact-form newslater">
                              <div className="form-group" >
                                  <input className="form-control" id="email2" name="email" type="email" placeholder="Email Address..." value={emailSubscribe} onChange={handleEmailSubscribe} required/> 
                                  <button type="submit" className="btn btn-custom">Subscribe</button>
                              </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
            </section>
          
            <section id="pricing" className="pricing-area pt-100 pb-50" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-8">
                            <div className="section-title text-center mb-50">     
                                <h2>Our plans</h2>
                                <p>A pricing that adapts to your needs.</p>
                            </div>
                            {/* <nav className="pricing-tab mb-60">
                        <span className="monthly_tab_title">
                        Monthly                 </span>
                        <span className="pricing-tab-switcher"></span>
                        <span className="annual_tab_title">
                        Annual                  </span>
                    </nav> */}
                        </div>
                        
                        
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="pricing-box text-center mb-60">
                                <div className="pricing-head">                                  
                                    <h4>Starter</h4>    
                                </div>
                                
                                <div className="pricing-body mb-40 text-left">
                                    
									<ul>
										<li> 100 articles</li>
										<li>15000 streams</li>
										<li>Access to the API</li>
										<li>Access to the dashboard</li>
										<li>Access to the player</li>
										<li>Video API option</li>
									</ul>
                                </div>
                                <div className="pricing-btn">
                                    <a href="#contact" className="btn">START</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="pricing-box active text-center mb-60">
                                <div className="poppuler">
                                    <span className="btn">Popular</span>
                                </div>
                                <div className="pricing-head">                                  
                                    <h4>Premium</h4>
                                </div>
                                <div className="pricing-body mb-40 text-left">
                                    
									<ul>
										<li>400 articles</li>
										<li>50000 streams</li>
										<li>Access to the API</li>
										<li>Access to the dashboard</li>
										<li>Access to the player</li>
										<li>Video API option</li>
									</ul>
                                </div>
                                <div className="pricing-btn">
                                    <a href="#contact" className="btn">START</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="pricing-box active text-center mb-60">                 
                                <div className="pricing-head">                                  
                                    <h4>Ultimate</h4>
                                </div>
                                
                                <div className="pricing-body mb-40 text-left">
                                    
									<ul>
										<li> > 2000 articles</li>
										<li>200000 streams</li>
                                        <li>Access to the API</li>
										<li>Access to the dashboard</li>
										<li>Access to the player</li>
										<li>Video API option</li>
									</ul>
                                </div>
                                <div className="pricing-btn">
                                    <a href="#contact" className="btn">START</a>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>			
            {/* <section id="testimonios" className="testimonial-area testimonial-p  pt-100 pb-70" >
                <div className="container">
                    <div className="row">    
                        <div className="col-lg-6">
                             <div className="section-title center-align">                              
                                <h2>Ce que disent nos clients</h2>
                                <p>Retours d'expériences de nos clients qui utlisent Podle pour ajouter une expérience audio à leurs plateformes</p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                           
                            <div className="testimonial-active">
                                <div className="col-xl-4">
                                    <div className="single-testimonial">
                                        <div className="testi-author text-left">
                                            <img src="img/testimonial/testi_avatar.png" alt="img"/>
                                            <div className="ta-info">
                                                <h6>Mr Jone Dose</h6>
                                                <span>Author</span>
                                            </div>
                                        </div>
                                        <div className="qutation"><img src="img/bg/qutation.png" alt="qutation.png"/></div>
                                        
                                        <p>Praesent fermentum nisl at ipsum fcilisis viverra elementum accumsan finibus. Cras placerat lacinia mitincidunt sit amet venenatis.</p>
                                        
                                    </div>
                                </div>
                                 <div className="col-xl-4">
                                    <div className="single-testimonial">
                                       <div className="testi-author text-left">
                                            <img src="img/testimonial/testi_avatar2.png" alt="img"/>
                                            <div className="ta-info">
                                                <h6>Mr Jone Dose</h6>
                                                <span>Author</span>
                                            </div>
                                        </div>
                                         <div className="qutation"><img src="img/bg/qutation.png" alt="qutation.png"/></div>
                                         <p>Praesent fermentum nisl at ipsum fcilisis viverra elementum accumsan finibus. Cras placerat lacinia mitincidunt sit amet venenatis.</p>
                                        
                                       
                                    </div>
                                </div>
                                 <div className="col-xl-4">
                                    <div className="single-testimonial">
                                         <div className="testi-author text-left">
                                            <img src="img/testimonial/testi_avatar3.png" alt="img"/>
                                            <div className="ta-info">
                                                <h6>Mr Jone Dose</h6>
                                                <span>Web Developer</span>
                                            </div>
                                        </div>
                                         <div className="qutation"><img src="img/bg/qutation.png" alt="qutation.png"/></div>
                                        <p>Praesent fermentum nisl at ipsum fcilisis viverra elementum accumsan finibus. Cras placerat lacinia mitincidunt sit amet venenatis.</p>
                                        
                                    </div>
                                </div>
                                 <div className="col-xl-4">
                                    <div className="single-testimonial">
                                        <div className="testi-author text-left">
                                            <img src="img/testimonial/testi_avatar.png" alt="img"/>
                                            <div className="ta-info">
                                                <h6>Mr Jone Dose</h6>
                                                <span>Web Developer</span>
                                            </div>
                                        </div>
                                        <div className="qutation"><img src="img/bg/qutation.png" alt="qutation.png"/></div>
                                         <p>Praesent fermentum nisl at ipsum fcilisis viverra elementum accumsan finibus. Cras placerat lacinia mitincidunt sit amet venenatis.</p>
                                        
                                    </div>
                                </div>
                                 <div className="col-xl-4">
                                    <div className="single-testimonial">
                                        <div className="testi-author text-left">
                                            <img src="img/testimonial/testi_avatar2.png" alt="img"/>
                                            <div className="ta-info">
                                                <h6>Mr Jone Dose</h6>
                                                <span>Web Developer</span>
                                            </div>
                                        </div>
                                        <div className="qutation"><img src="img/bg/qutation.png" alt="qutation.png"/></div>
                                        <p>Praesent fermentum nisl at ipsum fcilisis viverra elementum accumsan finibus. Cras placerat lacinia mitincidunt sit amet venenatis.</p>
                                        
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
						
                    </div>
                </div>
            </section> */}
           
           
            {/* <section id="blog" className="blog-area p-relative pt-70" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-10">
                            <div className="section-title text-center mb-50">                               
                                <h2>Our Latest Blog & News</h2>
                                <p>Quisque posuere mollis ipsum et molestie. Fusce cursus, risus vel scelerisque porttitor, leo quam vulputate nibh, sit amet blandit erat magna.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="single-post mb-30">
                                <div className="blog-thumb">
                                    <a href="blog-details.html"><img src="img/blog/inner_b1.jpg" alt="img"/></a>
                                </div>
                                <div className="blog-content">
                                    <div className="b-meta mb-40">
                                        <ul>                                           
                                            <li><a href="#">20 jan 2019</a></li>                                            
                                        </ul>
                                    </div>
                                    <h4><a href="blog-details.html">Making Distribut Product Team
                                    Work More With Monday</a></h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisi
                                    cing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <div className="admin">
                                            <ul>
                                                <li><img src="img/blog/admin-img.png" alt="test"/></li>
                                                <li><h6>Jhon Abraham</h6> Author</li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                             <div className="single-post mb-30">
                                <div className="blog-thumb">
                                    <a href="blog-details.html"><img src="img/blog/inner_b2.jpg" alt="img"/></a>
                                </div>
                                <div className="blog-content">
                                    <div className="b-meta mb-40">
                                        <ul>                                           
                                            <li><a href="#">20 jan 2019</a></li>                                         
                                        </ul>
                                    </div>
                                    <h4><a href="blog-details.html">Monthly Web Development Upto Cost Of JavaScript Ethics</a></h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisi
                                    cing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <div className="admin">
                                            <ul>
                                                 <li><img src="img/blog/admin-img.png" alt="test"/></li>
                                                <li><h6>Jhon Abraham</h6> Author</li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                             <div className="single-post mb-30">
                                <div className="blog-thumb">
                                    <a href="blog-details.html"><img src="img/blog/inner_b3.jpg" alt="img"/></a>
                                </div>
                                <div className="blog-content">
                                    <div className="b-meta mb-40">
                                        <ul>                                           
                                            <li><a href="#">20 jan 2019</a></li>
                                        </ul>
                                    </div>
                                    <h4><a href="blog-details.html">User Experience Psychology And Performance Smashing</a></h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisi
                                    cing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                     <div className="admin">
                                            <ul>
                                                 <li><img src="img/blog/admin-img.png" alt="test"/></li>
                                                <li><h6>Jhon Abraham</h6> Author</li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
			
            <section id="contact" className="contact-area contact-bg  pt-50 pb-100 p-relative fix"  >
                <div className="container">
             
					<div className="row">
						<div className="col-lg-6">
                            <div className="contact-img2">
                                {/* <img src="img/bg/illustration.png" alt="test"/> */}
                                <InlineWidget url="https://calendly.com/podleio/podlemeeting?hide_event_type_details=1&hide_gdpr_banner=1" styles={{height:"700px"}} />
                            </div>						
						</div>
						<div className="col-lg-6">
                            <div className="section-title mb-40">                              
                                <h2>Contact us</h2>
                                <p>Send us a message and we’ll be happy to get back to you for a demo or to answer your questions.</p>
                            </div>
						<form className="contact-form" onSubmit={sendMessage}>
							<div className="row">
                            <div className="col-lg-12">
                                <div className="contact-field p-relative c-name mb-20">                                    
                                    <input type="text" placeholder="Your name" value={name} onChange={handleNameChange} required/>
                                </div>                               
                            </div>
							<div className="col-lg-12">                    
                                <div className="contact-field p-relative c-email mb-20">                                    
                                    <input type="email" placeholder="Your email" value={email} onChange={handleEmailChange} required/>
                                </div>                                
                            </div>
                            <div className="col-lg-12">
                                <div className="contact-field p-relative c-message mb-45">                                  
                                    <textarea name="message" id="message" cols="10" rows="10" placeholder="Message" value={message} onChange={handleMessageChange} required></textarea>
                                </div>
                                <button className="btn" type="submit" >Send</button>
                            </div>
                            </div>
                        
                        </form>
						</div>
					</div>
                    
                </div>
               
            </section>
        </main>
        <footer id="footer" className="footer-bg footer-p pt-60" >            
            <div className="footer-top">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-4 col-lg-4 col-sm-6" align="center">
                            <div className="footer-widget mb-30">
                                <div className="logo mt-15 mb-15">
                                    <a href="#"><img src="img/logo-podle-white.svg" alt="logo" width="50px"/></a>
                                </div>
                                <div className="footer-text mb-20">
                                    <p>Podle SAS</p>
                                    <p>Give your content superpowers !</p>
                                </div>
                                <div className="footer-social">
                                    <a href="https://twitter.com/PodleIo"><i className="fab fa-twitter"></i></a>
                                    <a href="https://www.instagram.com/podleio/"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                    <h5>Useful links</h5>
                                </div>
                                <div className="footer-link">
                                    <ul>
                                        <li><a href="#">Home</a></li>
                                        <li><a href="https://www.podle.io/privacy">Legal Notice</a></li>                                   
                                    </ul>
                                </div>
                            </div>
                        </div>                        
                        <div className="col-xl-3 col-lg-3 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                    <h5>Contact us</h5>
                                </div>
                                <div className="footer-link">
                                    <div className="f-contact">
                                    <ul>
                                    {/* <li>
                                        <i className="icon dripicons-phone"></i>
                                        <span>1800-121-3637<br/>+91 555 234-8765</span>
                                    </li> */}
                                    <li>
                                        <i className="fal fa-envelope"></i>
                                         <span><a href="mailto:contact@podle.io">contact@podle.io</a></span>
                                    </li>
                                    <li>
                                      <i className="fal fa-map-marker-alt"></i>
                                         <span>Podle SAS, 62 rue Raymond Marcheron<br/>92170 VANVES FRANCE</span>
                                    </li>
                                </ul>
                                    
                                    </div>
                                   
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-wrap text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-text">
                                <p>&copy; Copyright Podle  2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer> 
        <ToastContainer />
        <Helmet>
            <script src="js/vendor/modernizr-3.5.0.min.js"></script>
            <script src="js/vendor/jquery-1.12.4.min.js"></script>
            <script src="js/popper.min.js"></script>
            <script src="js/bootstrap.min.js"></script>
            <script src="js/one-page-nav-min.js"></script>
            <script src="js/slick.min.js"></script>
            <script src="js/ajax-form.js"></script>
            <script src="js/paroller.js"></script>
            <script src="js/wow.min.js"></script>
            <script src="js/js_isotope.pkgd.min.js"></script>
            <script src="js/imagesloaded.min.js"></script>
            <script src="js/parallax.min.js"></script>
            <script src="js/jquery.waypoints.min.js"></script>
            <script src="js/jquery.counterup.min.js"></script>
            <script src="js/jquery.scrollUp.min.js"></script>
            <script src="js/typed.js"></script>
            <script src="js/parallax-scroll.js"></script>
            <script src="js/jquery.magnific-popup.min.js"></script>
            <script src="js/element-in-view.js"></script>
            <script src="js/swiper.min.js"></script>
            <script src="js/main.js"></script>
        </Helmet>       
    </div>
  );
}

export default App;
